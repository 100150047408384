<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!--"sub_new_style01" class적용시 상단라인 삭제-->
    <!-- 이벤트 맵핑 영역 -->
    <h2 class="h2-title-style">이벤트 맵핑</h2>

    <table class="table_list pad_le10">
      <colgroup>
        <col style="width: 350px; min-width: 350px" />
        <col style="" />
      </colgroup>
      <tr>
        <td>
          <div class="fl">
            <esp-dx-data-grid :data-grid="dataGrid1" ref="dataGrid1" @row-click="onRowClick1" @saving="onSaving" />
          </div>
        </td>
        <td>
          <div class="pad_le20">
            <DxChart
              id="chart1"
              :sticky-hovering="false"
              :data-source="this.chartList1"
              @pointClick="onPointClick"
              @legend-click="onLegendClick"
            >
              <DxCommonSeriesSettings argument-field="ymd" type="line">
                <DxPoint hover-mode="allArgumentPoints" :size="8" />
              </DxCommonSeriesSettings>
              <DxArgumentAxis :visual-range="initialRange1">
                <DxLabel :customize-text="customizeArgumentText" />
              </DxArgumentAxis>
              <DxValueAxis :tick-interval="2000">
                <DxLabel format="fixedPoint" />
              </DxValueAxis>
              <DxSeries value-field="offer2" name="예측값" color="#0163a0" />
              <DxSeries value-field="offer1" name="실제값" color="#00ffd5" />
              <DxScrollBar :visible="true" />
              <DxZoomAndPan argument-axis="both" />
              <DxLegend
                position="outside"
                vertical-alignment="bottom"
                horizontal-alignment="right"
                :row-item-spacing="1"
                :customizeItems="customizeLegendItems"
              />
              <DxExport :enabled="false" />
              <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
            </DxChart>
          </div>
        </td>
      </tr>
    </table>
    <!-- 이벤트 맵핑 영역 -->

    <!-- 모델 교체 영역 -->
    <h2 class="h2-title-style" style="margin-top: 40px">모델 교체</h2>

    <table class="table_list pad_le10">
      <colgroup>
        <col style="width: 600px; min-width: 600px" />
        <col style="" />
      </colgroup>
      <tr>
        <td>
          <div class="fl">
            <DxDataGrid :data-grid="dataGrid2" ref="dataGrid2" @row-click="onRowClick2" />
          </div>
        </td>
        <td>
          <div class="pad_le20">
            <DxChart
              id="chart2"
              :sticky-hovering="false"
              :data-source="this.chartList2"
              @pointClick="onPointClick"
              @legend-click="onLegendClick"
            >
              <DxCommonSeriesSettings argument-field="ymd" type="line">
                <DxPoint hover-mode="allArgumentPoints" :size="8" />
              </DxCommonSeriesSettings>
              <DxArgumentAxis :visual-range="initialRange2">
                <DxLabel :customize-text="customizeArgumentText" />
              </DxArgumentAxis>
              <DxValueAxis :tick-interval="2000">
                <DxLabel format="fixedPoint" />
              </DxValueAxis>
              <DxSeries value-field="offer2" name="예측값" color="#0163a0" />
              <DxSeries value-field="offer1" name="실제값" color="#00ffd5" />
              <DxScrollBar :visible="true" />
              <DxZoomAndPan argument-axis="both" />
              <DxLegend
                position="outside"
                vertical-alignment="bottom"
                horizontal-alignment="right"
                :row-item-spacing="1"
                :customizeItems="customizeLegendItems"
              />
              <DxExport :enabled="false" />
              <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
            </DxChart>
          </div>
        </td>
      </tr>
    </table>
    <!-- 모델 교체 영역 -->
  </div>
</template>

<script>
  import Query from 'devextreme/data/query';

  import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
    DxTooltip,
    DxValueAxis,
    DxArgumentAxis,
    DxZoomAndPan,
    DxScrollBar,
    DxPoint,
  } from 'devextreme-vue/chart';
  import { DxToolbarItem } from 'devextreme-vue/popup';
  import DxDateBox from 'devextreme-vue/date-box';
  import DxButton from 'devextreme-vue/button';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import moment from 'moment/moment';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxPoint,
      DxScrollBar,
      DxZoomAndPan,
      DxArgumentAxis,
      DxValueAxis,
      DxSelectBox,
      DxChart,
      DxSeries,
      DxCommonSeriesSettings,
      DxLabel,
      DxFormat,
      DxLegend,
      DxExport,
      DxDateBox,
      DxButton,
      DxTooltip,
      DxRadioGroup,
      DxToolbarItem,
    },
    data() {
      return {
        config: {},
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid1: {
          refName: 'dataGrid1',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          width: '350', // 주석처리시 100%
          height: '300', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
            rowClick: true,
            saving: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '이벤트 날짜',
              dataField: 'ds',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.customizeColumnText,
            },
            {
              caption: '이벤트 유형',
              dataField: 'event',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: [],
                displayExpr: 'codeNm',
                valueExpr: 'id',
              },
              requiredRule: {
                message: '이벤트 유형값은 필수입니다.',
              },
            },
          ],
        },
        dataGrid2: {
          refName: 'dataGrid2',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          width: '850', // 주석처리시 100%
          height: '300', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            optionChanged: true,
            rowClick: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },

          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '예측 주기',
              dataField: 'period',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '교체 날짜',
              dataField: 'insertion_ds',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.customizeColumnText,
            },
            {
              caption: '예측 실패 기간',
              dataField: 'fail_ds',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.formatFailDt,
            },
            {
              caption: '평균 정확도',
              dataField: 'mape',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: this.formatPercent,
            },
            {
              caption: '모델',
              dataField: 'model',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '하이퍼파라미터',
              dataField: 'hyperparameter',
              width: 150,
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
          ],
        },
        chartList1: [],
        chartList2: [],
        startDate: null,
        endDate: null,
        initialRange1: [],
        initialRange2: [],
      };
    },
    computed: {},
    methods: {
      onPointClick({ target }) {
        target.select();
      },
      customizeTooltip(pointInfo) {
        return {
          html: `
        <div id="forecast-config-tooltip">
            <div class='forecast-config-tooltip-header'>${this.formatDt(pointInfo.argumentText, 'YYYY.MM.DD')}</div>
            <div class='forecast-config-tooltip-body'>
                <div class='series-name'><span class='top-series-name'>${pointInfo.seriesName}</span>: </div>
                <div class='value-text'><span class='top-series-value'>${pointInfo.valueText
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></div>
            </div>
        </div>
        `,
        };
      },
      onLegendClick({ target: series }) {
        if (series.isVisible()) {
          series.hide();
        } else {
          series.show();
        }
      },
      onRowClick1(row) {
        this.chartList1 = row.data.data;
        this.initialRange1 = [row.data.data[row.data.data.length - 16].ymd, row.data.data[row.data.data.length - 1].ymd];
      },
      onRowClick2(row) {
        this.chartList2 = row.data.data;
        this.initialRange2 = [row.data.data[row.data.data.length - 8].ymd, row.data.data[row.data.data.length - 1].ymd];
      },
      formatDt(date, format) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', format);
      },
      customizeArgumentText(e) {
        return this.formatDt(e.value, 'YYYY.MM.DD');
      },
      customizeLegendItems(items) {
        items.forEach(item => {
          item.marker.size = 8;
        });
      },
      customizeColumnText(rowData) {
        if (rowData.insertion_ds !== undefined) {
          return this.formatDt(rowData.insertion_ds, 'YYYY.MM.DD');
        } else {
          return this.formatDt(rowData.ds, 'YYYY.MM.DD HH:mm:ss');
        }
      },
      formatPercent(rowData) {
        return `${rowData.mape}%`;
      },
      formatFailDt(rowData) {
        var fail_ds = rowData.fail_ds.split('~');
        return (
          this.$_commonlib.formatDate(fail_ds[0], 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD') +
          ' ~ ' +
          this.$_commonlib.formatDate(fail_ds[1], 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD')
        );
      },
      getFormatDate(date) {
        let year = date.getFullYear(); //yyyy
        let month = 1 + date.getMonth(); //M
        month = month >= 10 ? month : '0' + month; //month 두자리로 저장
        let day = date.getDate(); //d
        day = day >= 10 ? day : '0' + day; //day 두자리로 저장
        return year + '' + month + '' + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
      },
      async onSaving(e) {
        this.$_Toast('저장 기능 구현 예정', '메세지');
        return false;
      },
      async selectListData(startdate, enddate) {
        let paramsData = {
          startdate: this.$_commonlib.formatDate(startdate, 'YYYY/MM/DD', 'YYYYMMDD'),
          enddate: this.$_commonlib.formatDate(enddate, 'YYYY/MM/DD', 'YYYYMMDD'),
        };

        let payload = {
          actionname: 'FORECAST_LIST_ALL',
          data: { params: paramsData },
          loading: true,
        };

        // let res = await this.CALL_API(payload);
        // let res2 = await this.CALL_API(payload);
        let res = await { data: { data: [], header: { totalCount: 0, resCode: 'success' } }, status: 200 };
        let res2 = await { data: { data: [], header: { totalCount: 0, resCode: 'success' } }, status: 200 };

        if (res.status === 200) {
          if (res.data.header.resCode === 'success') {
            /////////// 임시로 데이터 넣음. 실데이터 받아오는로직으로 변경해야함 //////////////
            var startDate = new Date();
            var endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + Math.floor(Math.random() * (50 - 20) + 20));
            for (let i = 0; endDate > startDate; i++) {
              let h = Math.floor(Math.random() * (19 - 9 + 1) + 9);
              h = h >= 10 ? h + '' : '0' + h;
              let m = Math.floor(Math.random() * 59);
              m = m >= 10 ? m + '' : '0' + m;
              let s = Math.floor(Math.random() * 59);
              s = s >= 10 ? s + '' : '0' + s;

              let timestamp = this.getFormatDate(startDate) + h + m + s;
              let event = Math.floor(Math.random() * 3);
              switch (event) {
                case 0:
                  event = 1104;
                  break;
                case 1:
                  event = 1105;
                  break;
                case 2:
                  event = 1106;
                  break;
                default:
              }

              res.data.data[i] = {
                ds: timestamp,
                event: event,
                data: [],
              };

              let endDate2 = new Date(startDate);
              endDate2.setDate(endDate2.getDate() - Math.floor(Math.random() * (100 - 30) + 30));
              let fixOffer1 = Math.floor(Math.random() * (100000 - 50000) + 50000);
              for (let j = 0; endDate2 < startDate; j++) {
                res.data.data[i].data[j] = {
                  ymd: this.getFormatDate(endDate2),
                  offer1: fixOffer1,
                  offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                };
                endDate2.setDate(endDate2.getDate() + 1);
              }
              startDate.setDate(startDate.getDate() + 1);
            }
            res.data.header.totalCount = res.data.data.length;

            /////////////////////////////////////////////////////////////////////

            var startDate = new Date();
            var endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + Math.floor(Math.random() * (50 - 20) + 20));
            for (let i = 0; endDate > startDate; i++) {
              let mape = Math.floor(Math.random() * (100 - 85 + 1) + 85);
              let param = Math.floor(Math.random() * 10 + 1);
              let period = Math.floor(Math.random() * 2);
              switch (period) {
                case 0:
                  period = '일별';
                  break;
                case 1:
                  period = '월별';
                  break;
                default:
              }

              var endDate2 = new Date(startDate);
              endDate2.setDate(endDate2.getDate() - param);

              res2.data.data[i] = {
                period: period,
                fail_ds: this.getFormatDate(endDate2) + '~' + this.getFormatDate(startDate),
                insertion_ds: this.getFormatDate(startDate),
                mape: mape,
                model: 'propet',
                hyperparameter: 'param=' + param,
                data: [],
              };

              var endDate2 = new Date(startDate);
              endDate2.setDate(endDate2.getDate() - Math.floor(Math.random() * (100 - 30) + 30));
              let fixOffer1 = Math.floor(Math.random() * (100000 - 50000) + 50000);
              for (let j = 0; endDate2 < startDate; j++) {
                res2.data.data[i].data[j] = {
                  ymd: this.getFormatDate(endDate2),
                  offer1: fixOffer1,
                  offer2: Math.floor(Math.random() * (100000 - 50000) + 50000),
                };
                endDate2.setDate(endDate2.getDate() + 1);
              }
              startDate.setDate(startDate.getDate() + 1);
            }
            res2.data.header.totalCount = res2.data.data.length;
            this.$refs.dataGrid1.totalCount = res.data.header.totalCount;
            this.dataGrid1.dataSource = res.data.data;
            this.$refs.dataGrid2.totalCount = res2.data.header.totalCount;
            this.dataGrid2.dataSource = res2.data.data;
          }
        }
      },
      creatData() {
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.selectListData(this.startDate, this.endDate);
      },
    },
    created() {
      this.creatData();
    },
    mounted() {
      const cssRule = `div.content:has(.page-sub-box[${this.$vnode.elm.attributes[0].name}]) { min-width: 1600px; }`;
      const styleElement = document.createElement('style');
      const cssText = document.createTextNode(cssRule);
      styleElement.appendChild(cssText);
      document.head.appendChild(styleElement);
      this.dataGrid1.columns[1].lookup.dataSource = this.$_getCode('forecast_models');
    },
  };
</script>

<style>
  .dxc-tooltip:has(#forecast-config-tooltip) {
    z-index: 3;
  }

  .forecast-config-tooltip-header {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }

  .forecast-config-tooltip-body {
    width: 130px;
  }

  .forecast-config-tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 86px;
  }

  .forecast-config-tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
  }
</style>

<!-- content div min-width 지정을 위해 필요 -->
<style scoped>
  .page-sub-box.sub_new_style01.sub_ui_box1 {
    padding-bottom: 0 !important;
  }

  .h2-title-style {
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    border-top: 1px solid #ebebeb;
    padding: 20px 0 10px 3px;
  }

  #chart1 {
    width: 1150px;
    height: 230px;
    margin-top: 70px;
  }

  #chart2 {
    width: 650px;
    height: 230px;
    margin-top: 70px;
  }
</style>
